exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-how-stepper-works-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/blog/How-stepper-works/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-how-stepper-works-index-mdx" */),
  "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-splice-grafting-in-tomato-index-mdx": () => import("./../../../src/pages/blog/{mdx.frontmatter__slug}.js?__contentFilePath=/opt/buildhome/repo/blog/Splice-grafting-in-tomato/index.mdx" /* webpackChunkName: "component---src-pages-blog-mdx-frontmatter-slug-js-content-file-path-blog-splice-grafting-in-tomato-index-mdx" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-products-js": () => import("./../../../src/pages/products.js" /* webpackChunkName: "component---src-pages-products-js" */)
}

